<template>
<div class="sty">
    <img src="../../../../public/img/404.png" alt="" class="">
    <div>
       <p>找不到页面啦~</p> 
       <el-button type="text" style="margin-left:20px" @click="$router.push({name:'home'}) ">返回首页</el-button>
    </div>
</div>
</template>
<script>

export default {
    name: 'error_404',
    components: { },
    data() {
        return {}
    },
    methods:{
        
    }
}
</script>
<style scoped >
.sty{
    text-align: center;
}
.sty div{
   margin-top: -50px;
   font-size: 14px;
}
p{
  display: inline-block;      
}
.sty img{
    height: 700px;
}

</style>
